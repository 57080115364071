import React from 'react'
const ConsultDermNotice = () => {
  return (
    <div>
      {process.env.REACT_APP_PROVINCE === 'BRITISH COLUMBIA' && (
        <div className="flex flex-col items-center justify-center py-12 font-semibold text-sm text-gray-700">
          <p>To review old consult history, please click on the link below. Please note that the old ConsultDerm site can not receive new consult requests. Previous username and password are unchanged</p>
          <a className='underline' href='https://bc.consultderm.ca'>B.C ConsultDerm site - Click here</a>
        </div>
      )}
    </div>
  )
}

export default ConsultDermNotice
